import React, { Component } from 'react';
import axios from "axios";
import Api from "../../customlib/Api";
import moment from "moment";
import Global from "../../customlib/Global"


import { makeStyles } from '@mui/styles';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Typography, CircularProgress, ToggleButtonGroup, ToggleButton, TextField} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExportCheckPointReport from './ExportCheckPointReport';
import { Search } from '@mui/icons-material';

class CheckPointReport extends Component {
    SHIFT_PAGI = "PAGI";
    SHIFT_SIANG = "SIANG";
    SHIFT_MALAM = "MALAM";
    state = { 
        contactLeads : [],
        sales : [],
        contactSelectedToUpdate: [],
        checkAll : null,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        page: 0,
        rowsPerPage: 30,
        appProfileId: "",
        dateRangeValue: [null,null],
        isLoading: false,
        shift: this.SHIFT_PAGI
    }
    
    componentDidMount() {
        this.setState(
          {appProfileId: Global.getAppProfile()._id}, ()=>{
            this.getContactLeads();
          }
        );
    }

    getContactLeads = () => {
        console.log("STARTDATE : ",this.state.startDate);
        var userData = Global.get();
        console.log("USERDATA : ", userData);
        console.log("USERDATA", userData);

        const params = {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          appProfileId: this.state.appProfileId,
          shift: this.state.shift
          // token: "07d0b91e771752005d94ceb5c5efdc0a"
        }

        this.setState({contactLeads: [], isLoading: true});

        axios
          .post(Api.checkPointReport(), params, Api.getRequestHeader())
          .then((response) => {
            console.log("CONTACTLEADS : ", response.data);
            
            var tmpContactLeads = response.data.data;
            
            if(userData.admin === false){
                tmpContactLeads = tmpContactLeads.filter(element => element.userId === userData.user.id);
            }

            const arrayLabels = [
              Global.labelPaid(),
              Global.labelComplaintKeterlambatan(),
              Global.labelComplaintProdukTidakSesuai(),
              Global.labelComplaintLayuRusak(),
              Global.labelComplaintReport(),
              Global.labelReport(),
              Global.labelKonfirmasiPesanan(),
              Global.labelComplaintPesananTidakDibuatkan(),
              Global.labelLainLain()
            ];

            tmpContactLeads.forEach(lead => {
                lead.leadsCount = lead.detail.length;
                lead.labelUnpaid = 0;
                lead.labelPaid = 0;
                lead.labelDp = 0;
                lead.labelCancel = 0;
                lead.labelPending = 0;

                lead.detail.forEach(contact => {


                  if(arrayLabels.includes(contact.label)){
                    lead.labelPaid += 1;
                  }
    
                  if(contact.label === Global.labelUnpaid()){
                    lead.labelUnpaid += 1;
                  }
    
                  if(contact.label === Global.labelDp()){
                    lead.labelDp += 1;
                  }
    
                  if(contact.label === Global.labelCancel()){
                    lead.labelCancel += 1;
                  }

                  if(contact.label === Global.labelPending()){
                    lead.labelPending += 1;
                  }
    
                });

            });

            this.setState({contactLeads: tmpContactLeads, isLoading: false});
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      };

    handleStartDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ startDate:  formatStartDate}, this.getContactLeads);
        // this.state.startDate = formatStartDate;
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    handleEndDateChange = (event) => {
        var formatStartDate = event.target.value.replace("/", "-");
        console.log(formatStartDate);
        this.setState({ endDate:  formatStartDate}, this.getContactLeads);
        // this.getContactLeads();
        // console.log(this.state.email);
    };

    columns = [
        { id: 'userName', label: 'User', minWidth: 170 },
        { id: 'userType', label: 'Type', minWidth: 100 },
        { id: 'appLabel', label: 'Aplikasi', minWidth: 100 },
        { id: 'checkPointCount', label: 'Check Point Count', minWidth: 100 },
        { id: 'targetPercent', label: 'Target Percent', minWidth: 100 }
    ];

    handleDateChanged = (rangeDate) => {

      let startDate =  moment(rangeDate[0]).format("YYYY-MM-DD");
      let endDate =  moment(rangeDate[1]).format("YYYY-MM-DD");

      console.log("START : ", startDate, ", END : ", endDate);

      this.setState({ startDate: startDate, endDate:  endDate}, this.getContactLeads);
    }

    handleSetNewDateValue = (newDateValue) => {
      this.setState({dateRangeValue: [...newDateValue],});
    }

    handleChangeShift = (event, value) => {
      this.setState({shift: value}, () => {
        this.getContactLeads();
      });
    }

    render() { 
        return ( 
            <Paper style={{padding:20}}>
              <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="panel-title">
                            {/* <h1>Setup Contact</h1> */}
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row align-items-center">
                            <div className="col-3">
                                <strong>Total {this.state.contactLeads.length} Salesman</strong>
                            </div>
                            <div className="col-3">
                            {
                                Global.get().admin === true ? <ExportCheckPointReport leadsReportData={this.state.contactLeads} /> : <></>
                            }
                            
                            </div>
                            <div className="col-3">
                              Shift :&nbsp;
                              <ToggleButtonGroup
                                color="secondary"
                                value={this.state.shift}
                                exclusive
                                onChange={this.handleChangeShift}
                                aria-label="Shift"
                              >
                                <ToggleButton value="PAGI">Pagi</ToggleButton>
                                <ToggleButton value="SIANG">Siang</ToggleButton>
                                <ToggleButton value="MALAM">Malam</ToggleButton>
                              </ToggleButtonGroup>
                            </div>
                            <div className="col-2 text-right" style={{borderRadius: 10, padding: 5}}>
                            <TextField
                              id="tanggal"
                              label="Tanggal"
                              value={this.state.startDate}
                              onChange={(event) => {
                                console.log(event.target.value);
                                let selectedDate = event.target.value;
                                this.setState({startDate: selectedDate, endDate: selectedDate}, () => {
                                  this.getContactLeads();
                                });
                              }}
                              type="date"
                            />
                                {/* <MyDateRangePicker onDateChanged={this.handleDateChanged} /> */}
                            </div>
                            <div className="col-1">
                            {
                                    this.state.isLoading === true ? 
                                        (<CircularProgress style={{marginTop: 15}} size={30} color="secondary" />) : 
                                        (<div onClick={() => {this.getContactLeads()}} className="btn btn-primary" style={{padding: 15}}><Search /></div>)
                                }
                            </div>
                        </div>
                        <br />
                        <Paper className="root">
                            <TableContainer>
                                <Table aria-label="sticky table" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20px" />
                                    {this.columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.contactLeads.map((row) => {
                                    return (
                                        <Row key={row.salesId} row={row} />
                                    );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="panel-footer">
                        
                    </div>
                </div>
              </div>
            </Paper>
         );
    }
}
 
export default CheckPointReport;

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell width="20px">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.userName}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.userType}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.appLabel}
          </TableCell>
          <TableCell align="center">{row.checkPointCount}</TableCell>
          <TableCell component="th" scope="row" align="center">{row.targetPercent}%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Detail Check Point
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Is Notified?</strong></TableCell>
                      <TableCell><strong>Notified At</strong></TableCell>
                      <TableCell><strong>Check In</strong></TableCell>
                      <TableCell><strong>Check In At</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.detail.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          {historyRow.isNotified === true ? "Yes" : "Not Yet"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {
                            historyRow.notifiedAt !== null ? Global.formatDateTimeToStrDateTime24(historyRow.notifiedAt) : "-"
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {
                            historyRow.isNotified && historyRow.checkPointSucceeded ? "Succeed" : 
                            historyRow.isNotified && !historyRow.checkPointSucceeded ? "Missed" : 
                            !historyRow.isNotified ? "-" : ""
                          }
                          {/* {historyRow.checkPointSucceeded === true ? "Succeed" : "Failed"} */}
                        </TableCell>
                        <TableCell align="left">
                          {
                            historyRow.checkedAt !== null ? Global.formatDateTimeToStrDateTime24(historyRow.checkedAt) : "-"
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function MyDateRangePicker(props){
    const [dateRange, setDateRange] = React.useState([Date.now(), Date.now()]);
    const [startDate, endDate] = dateRange;
    const [message, setMessage] = React.useState("");
    return (
      <>
        <DatePicker
          readOnly={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          dateFormat="d-M-yyyy"
          onChange={(update) => {
            console.log("RANGE", update);
            
            if(update[0] !== null && update[1] !== null){
              let startDate = moment(update[0]);
              let endDate = moment(update[1]);
              let diffs = Math.abs(startDate.diff(endDate, "days"));
              console.log("DIFFS", diffs);
              if(diffs > 35){
                setDateRange([null, null]);
                setMessage("Rentang tanggal max 35 hari");
                return
              }
            }
            
            setDateRange(update);
            setMessage("");
            if(update[0] !== null && update[1] !== null){
              props.onDateChanged(update);
            }

          }}
          isClearable={false}
          popperPlacement="top-end"
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [5, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className="text-danger">
          {message}
        </div>
      </>
    );
  }